import React from 'react'
import './PageNotFound.css'

function PageNotFound() {
    return (
        <div className='error-message-404'>
            Error 404:  Page not found!
        </div>
    )
}

export default PageNotFound
